import _path from "path";
import _binaryExtensions from "binary-extensions";
var exports = {};
var path = _path;
var binaryExtensions = _binaryExtensions;
var exts = Object.create(null);
binaryExtensions.forEach(function (el) {
  exts[el] = true;
});

exports = function (filepath) {
  return path.extname(filepath).slice(1).toLowerCase() in exts;
};

export default exports;